import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { Goals, TimerIcon } from "images/Investments/Goals";
import { NewInvestContext } from "@context";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { navigate } from "gatsby";
import { useMediaQuery } from "@material-ui/core";
import { useApolloClient } from "@apollo/client";
import { deleteGoal, useCurrentUser } from "@apollo";
import {
  ahorroTarjeta,
  casaTarjeta,
  deudasTarjeta,
  jubilacionTarjeta,
  viajeTarjeta,
  emergenciaTarjeta,
  otroTarjeta,
} from "@images";
import { HouseIcon, CoinIcon, PlaneIcon } from "images/Investments/Goals";
import { Route, MovementType } from "@interfaces";
import { useLocation } from "@reach/router";

interface propsDataGoal {
  nameGoal: string;
  timeLeft: string;
  iconGoal: string;
  className?: string;
  goalId: number;
  data: any;
  valueGoal: number;
}

const DataGoal = ({
  nameGoal,
  timeLeft,
  iconGoal,
  className,
  goalId,
  data,
  valueGoal,
}: propsDataGoal) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [icon, setIcon] = useState(otroTarjeta);
  const [value, setValue] = useState<number>(0);
  const [valueRequired, setValueRequired] = useState<number>(0);
  const isMobile = useMediaQuery("(max-width: 1280px)");
  const getTotalInvested = (data) => {
    const sum = data.reduce((accumulator, object) => {
      return accumulator + object.currentValue;
    }, 0);
    return sum;
  };

  useEffect(() => {
    if (data !== undefined) {
      setValueRequired(valueGoal);
      setValue(getTotalInvested(data));
    }
  }, [data, valueGoal]);

  useEffect(() => {
    if (iconGoal == "Ahorrar") {
      setIcon(ahorroTarjeta);
    }
    if (iconGoal == "Pagar deudas") {
      setIcon(deudasTarjeta);
    }
    if (iconGoal == "Mejorar mi jubilación") {
      setIcon(jubilacionTarjeta);
    }
    if (iconGoal == "Viajar") {
      setIcon(viajeTarjeta);
    }
    if (iconGoal == "Comprar una casa") {
      setIcon(casaTarjeta);
    }
    if (iconGoal == "Fondo de emergencia") {
      setIcon(emergenciaTarjeta);
    }
    if (iconGoal == "Otro") {
      setIcon(otroTarjeta);
    }
  }, [iconGoal]);
  const { openWithdrawFundsModal, openAddFundsModal, startFlow } =
    useContext(NewInvestContext);
  const { pathname, search: pathSearch } = useLocation();

  const handleAddFundsClick = () => {
    openAddFundsModal(goalId);
    startFlow({
      isReinvest: true,
      movementType: MovementType.DEPOSIT,
      data,
    });
  };

  const handleWithdrawFundsClick = () => {
    openWithdrawFundsModal(goalId);
    startFlow({
      movementType: MovementType.WITHDRAWAL,
      comingFrom: pathname + pathSearch,
    });
  };
  const [openVertMenu, setOpenVertMenu] = useState(false);
  return (
    <section className={`${classes.container} ${className}`}>
      <div className={classes.mainContainer}>
        <div className={classes.cardContainer}>
          <img className={classes.icon} src={icon} />
          <div className={classes.texts}>
            <span className={classes.nameGoal}>{nameGoal}</span>
            <div className={classes.timeGoal}>
              <img src={TimerIcon} />
              <div>
                <span className={classes.timeGoalTitle}>Tiempo restante</span>
              </div>
            </div>
            <span className={classes.timeGoalTimeLeft}>{timeLeft}</span>
          </div>
          <div className={classes.moreVert}>
            <button onClick={() => setOpenVertMenu(!openVertMenu)}>
              <MoreVertIcon htmlColor="#8352fd" />
            </button>
          </div>
          <div className={openVertMenu ? classes.moreVertMenu : classes.hidden}>
            <button
              className={classes.menuItem}
              onClick={() => navigate(Route.editGoal + `?id=${goalId}`)}
            >
              <p className={classes.menuText}>Editar</p>
            </button>
            <button className={classes.menuItem} onClick={handleAddFundsClick}>
              <p className={classes.menuText}>Agregar fondos</p>
            </button>
            <button
              className={classes.menuItem}
              onClick={handleWithdrawFundsClick}
            >
              <p className={classes.menuText}>Retirar fondos</p>
            </button>
            <button className={classes.menuItem}>
              <p className={classes.menuTextDisabled}>Eliminar</p>
            </button>
          </div>
        </div>
        <div className={isMobile ? classes.valueMobile : classes.hidden}>
          <p>Saldo total: ${value.toLocaleString("es")}</p>
        </div>
      </div>
    </section>
  );
};

export default DataGoal;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  container: {
    justifyContent: "center",
    display: "flex",
  },
  cardContainer: {
    zIndex: 4,
    width: "400px",
    position: "relative",
    height: "auto",
    background: "white",
    border: "0.1875rem solid #8352fd",
    borderRadius: "21.8px",
    boxShadow: "1.25rem 0.75rem 3.125rem 0 rgba(0, 0, 0, 0.2)",
    padding: "2.0625rem 1.875rem 2.125rem 0.875rem",
    display: "flex",
    marginLeft: "1.2rem",
    marginTop: "-3rem",
    [theme.breakpoints.down(1440)]: {},
    [theme.breakpoints.down(1280)]: {
      marginLeft: "0rem",
      width: "100%",
      padding: "1.15rem 1.875rem 1.15rem 0.875rem",
      height: "124px",
    },
  },
  icon: {
    marginRight: "1rem",
    [theme.breakpoints.down(1280)]: {
      maxWidth: "4.451rem;",
    },
  },
  moreVert: {
    display: "flex",
    position: "absolute",
    right: "1rem",
  },
  moreVertMenu: {
    width: "9.6rem",
    height: "10.6rem",
    background: "#fff",
    margin: "2rem 0 0 0",
    display: "flex",
    position: "absolute",
    right: "1rem",
    flexDirection: "column",
    borderRadius: "10.4px",
    boxShadow: "4px 4px 8px 2px rgba(0, 0, 0, 0.2)",
  },
  menuItem: {
    height: "2.6rem",
  },
  menuText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.875rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    color: "#7d7c7c",
    textAlign: "left",
    marginLeft: "1rem",
  },
  menuTextDisabled: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.875rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.29,
    letterSpacing: "normal",
    color: "#D3D3D3",
    textAlign: "left",
    marginLeft: "1rem",
  },
  nameGoal: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#393e44",
    [theme.breakpoints.down(1279)]: {
      fontSize: "0.908rem",
      fontWeight: "bold",
      fontFamily: "Montserrat",
      color: "#393e44",
    },
  },
  timeGoal: {
    display: "flex",
    alignItems: "start",
    top: 85,
    left: 45,
    color: "black",
    gap: 10,
    [theme.breakpoints.down(1440)]: {
      width: 160,
      top: 75,
      left: 35,
    },
    "& span": {
      display: "block",
    },
  },
  timeGoalTitle: {
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down(1279)]: {
      fontSize: "0.636rem",
      fontWeight: "normal",
      fontFamily: "Montserrat",
      color: "#6b6b6b",
      marginTop: 5,
    },
  },
  timeGoalTimeLeft: {
    fontWeight: "bold",
    color: "#393e44",
    [theme.breakpoints.down(1279)]: {
      fontSize: "0.636rem",
      fontWeight: 500,
      fontFamily: "Montserrat",
      color: "#393e44",
    },
  },
  iconGoal: {
    position: "absolute",
    right: -10,
    top: 60,
    zIndex: 4,
    width: 144,
    [theme.breakpoints.down(1440)]: {
      width: 135,
      top: 15,
      left: 270,
    },
    [theme.breakpoints.down(1000)]: {
      width: 112,
      top: 15,
      left: 240,
    },
  },
  texts: {
    display: "grid",
    gap: 4,
  },
  hidden: {
    display: "none",
  },
  valueMobile: {
    margin: "1rem 0 0 0",
    borderRadius: "14px",
    border: "1px solid #8352fd",
    width: "100%",
    height: "3.2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      textAlign: "center",
      fontSize: "1rem",
      fontWeight: 500,
      fontFamily: "Montserrat",
      color: "#393e44",
    },
  },
}));
