import React, { useContext, useEffect, useState } from "react";
import { useStyles } from "./InvestmentsLayout.style";
import { ButtonPrimary } from "@components";
import { useMediaQuery } from "@material-ui/core";
import { getUserMissions } from "@apollo";
import { Route } from "@interfaces";
import { CloseRounded } from "@material-ui/icons";
import { navigate } from "gatsby";
import Saving from "@components/Investments/Saving";
import Goals from "@components/Investments/Goals";
import {
  TARGET_LIGHT_BLUE,
  INVERTION_LIGHT_BLUE,
  SAFE_LIGHT_BLUE,
} from "images/AWS/Investments/index";
import {
  useCurrentCashBalance,
  usePortfolios,
  useUserInvestments,
  useHistoricalProfit,
  useCurrentUser,
} from "@apollo";
import { useAuth, useInvestmentsTotals } from "@hooks";
import SavingMobile from "@components/Investments/SavingMobile";
import ProfitabilityMobile from "@components/Investments/ProfitabilityMobile";
import { ProfileContext } from "@context";
import { TUserInfo } from "react-use-hotjar/dist/types";
import useHotjar from "react-use-hotjar";
import HeaderFinancialPortal from "@components/FinancialPortal/Header/Header";

const InvestmentsLayout = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(min-width: 1280px)");
  const isTablet = useMediaQuery("(max-width: 1440px)");

  const [isSelect, setSelect] = useState(0);

  const { profileForm } = useContext(ProfileContext);
  const { portfolios, loading: loadingPortfolios } = usePortfolios();
  const { investments, loading: loadingInvestments } = useUserInvestments();
  const { currentCashBalance, loading: loadingCurrentCashBalance } =
    useCurrentCashBalance();
  const { historicalProfit, loading: loading } = useHistoricalProfit();

  useAuth({ redirectIfNotLogged: true });

  const { identifyHotjar } = useHotjar();
  const myCustomLogger = console.log;
  const { user: currentUser } = useCurrentUser();
  const logininfo = {
    name: currentUser?.name,
    identification: process.env.HOTJAR_ID || "0",
  };
  const [openInfoPopUp, setOpenInfoPopUp] = useState(false);

  const infoButtonHandler = () => {
    setOpenInfoPopUp(!openInfoPopUp);
  };

  useEffect(() => {
    const location = window.location.href.split("#")[1];
    location && location === "objectives"
      ? setSelect(1)
      : location === "alliance" && setSelect(2);
  }, []);

  const ResumenButtonHandler = () => {
    setSelect(0);
    navigate(Route.investments);
  };

  const ObjectivesButtonHandler = () => {
    setSelect(1);
    navigate(Route.investments + "/?#objectives");
  };

  const AlliancesButtonHandler = () => {
    setSelect(2);
    navigate(Route.rokipedia + "/?#alliance");
  };

  useEffect(() => {
    if (logininfo) {
      const { identification, ...restOfInformation } = {
        name: currentUser?.name,
        identification: process.env.HOTJAR_ID || "0",
      };
      identifyHotjar(
        identification,
        restOfInformation.name as unknown as TUserInfo,
        myCustomLogger
      );
    }

    if (currentUser?.company && !currentUser.company.canInvest) {
      navigate(Route.finance);
    }
  }, [currentUser]);

  const globals = useInvestmentsTotals({
    investments,
    portfolios,
    currentCashBalance,
  });

  function groupArrayBy(arr, key) {
    return arr
      .reduce((acc, cur) => {
        acc[cur[key]] = [...(acc[cur[key]] || []), cur];
        return acc;
      }, [])
      .filter(Boolean);
  }

  const data = groupArrayBy([...portfolios, ...investments], "goalId");

  const { userMissions } = getUserMissions();

  const menuButtonHandler = () => {
    console.log("menu button");
  };

  return (
    <>
      <HeaderFinancialPortal
        setSelect={setSelect}
        isSelect={isSelect}
        infoPopup={infoButtonHandler}
        which={"investments"}
      >
        <div className={classes.MainContainer}>
          {isSelect === 0 && (
            <div className={classes.resumenData}>
              {isMobile ? (
                <>
                  <Saving globals={globals} />
                  <ProfitabilityMobile
                    globals={globals}
                    profileForm={profileForm}
                    historicalGraphData={historicalProfit}
                  />
                </>
              ) : (
                <>
                  <SavingMobile globals={globals} />
                  <ProfitabilityMobile
                    globals={globals}
                    profileForm={profileForm}
                    historicalGraphData={historicalProfit}
                  />
                  {/**<GoalsMobile data={data} />*/}
                </>
              )}
            </div>
          )}
          {isSelect === 1 && (
            <>
              <div className={classes.ObjectivesData}>
                <Goals data={data} />
              </div>
            </>
          )}
          {isSelect === 2 && (
            <div className={classes.MonitortingData}>
              {/*componente de monitoreos*/}
            </div>
          )}
        </div>
        {/* THIS IS THE POPUP */}
        <div
          className={
            openInfoPopUp ? classes.popUpContainer : classes.hiddenDisplay
          }
        >
          <div className={classes.outerBorder}>
            <div className={classes.popUp}>
              <div className={classes.itemRow}>
                <img src={INVERTION_LIGHT_BLUE} alt="" />
                <h3 className={classes.rokipediaPopUpTitle}>Mis Inversiones</h3>
                <button onClick={infoButtonHandler}>
                  <CloseRounded htmlColor="#393e44" />
                </button>
              </div>
              <div className={classes.popUpVector}></div>

              <p className={classes.subtitle}>
                Todas tus inversiones en un solo lugar:
              </p>

              <div className={classes.textButtonContainer}>
                <img src={SAFE_LIGHT_BLUE} alt="" className={classes.image} />
                <p className={classes.subtext}>
                  <strong className={classes.strongText}>Ahorra</strong> de
                  forma simple{" "}
                  <strong className={classes.strongText}>
                    invirtiendo en tus objetivos
                  </strong>{" "}
                </p>
              </div>
              {/*<div className={classes.textButtonContainer}>
              <img src={EYE_LIGHT_BLUE} alt="" className={classes.image2} />
              <p className={classes.subtext}>
                <strong className={classes.strongText}>
                  Monitorea inversiones
                </strong>{" "}
                que ya tienes con otras entidades
              </p>
            </div>*/}
              <div className={classes.textButtonContainer}>
                <img
                  src={TARGET_LIGHT_BLUE}
                  alt=""
                  className={classes.image2}
                />
                <p className={classes.subtext}>
                  <strong className={classes.strongText}>
                    Crea nuevos objetivos{" "}
                  </strong>{" "}
                  y alcanza tus metas!
                </p>
              </div>

              <div className={classes.buttonGotItContainer}>
                <ButtonPrimary
                  className={classes.buttonGotIt}
                  onClick={() => {
                    infoButtonHandler();
                  }}
                  text="Entendido"
                  secondaryVersion
                  noDegraded
                />
              </div>
            </div>
          </div>
        </div>
      </HeaderFinancialPortal>
    </>
  );
};

export default InvestmentsLayout;
