import React, { useContext, useEffect, useState } from "react";
import { navigate, PageProps } from "gatsby";
import { useMediaQuery } from "@material-ui/core";
import { LayoutOnBoarding, SEO } from "@components";
import Saving from "@components/Investments/Saving";
import Profitability from "@components/Investments/Profitability";
import Goals from "@components/Investments/Goals";
import {
  useCurrentCashBalance,
  usePortfolios,
  useUserInvestments,
  usePortfolioHistoricalValues,
  useInvestmentHistoricalValues,
  useHistoricalProfit,
  useCurrentUser,
} from "@apollo";
import { useAuth, useInvestmentsTotals } from "@hooks";
import SavingMobile from "@components/Investments/SavingMobile";
import ProfitabilityMobile from "@components/Investments/ProfitabilityMobile";
import GoalsMobile from "@components/Investments/GoalsMobile";
import { LayoutMobileInvestments } from "@components/Investments/Layout";
import { ProfileContext } from "@context";
import { TUserInfo } from "react-use-hotjar/dist/types";
import useHotjar from "react-use-hotjar";
import { Route } from "@interfaces";
import InvestmentsLayout from "@components/Investments/InvestmentsLayout/InvestmentsLayout";
import { MenuRounded, InfoOutlined, CloseRounded } from "@material-ui/icons";

const Investments: React.FC<PageProps> = () => {
  const isMobile = useMediaQuery("(min-width: 1240px)");
  const isTablet = useMediaQuery("(max-width: 1440px)");

  const { profileForm } = useContext(ProfileContext);
  const { portfolios, loading: loadingPortfolios } = usePortfolios();
  const { investments, loading: loadingInvestments } = useUserInvestments();
  const { currentCashBalance, loading: loadingCurrentCashBalance } =
    useCurrentCashBalance();
  const { historicalProfit, loading: loading } = useHistoricalProfit();

  useAuth({ redirectIfNotLogged: true });

  const { identifyHotjar } = useHotjar();
  const myCustomLogger = console.log;
  const { user: currentUser } = useCurrentUser();
  const logininfo = {
    name: currentUser?.name,
    identification: process.env.HOTJAR_ID || "0",
  };
  useEffect(() => {
    if (logininfo) {
      const { identification, ...restOfInformation } = {
        name: currentUser?.name,
        identification: process.env.HOTJAR_ID || "0",
      };
      identifyHotjar(
        identification,
        restOfInformation.name as unknown as TUserInfo,
        myCustomLogger
      );
    }

    if (currentUser?.company && !currentUser.company.canInvest) {
      navigate(Route.finance);
    }
  }, [currentUser]);

  const globals = useInvestmentsTotals({
    investments,
    portfolios,
    currentCashBalance,
  });

  function groupArrayBy(arr, key) {
    return arr
      .reduce((acc, cur) => {
        acc[cur[key]] = [...(acc[cur[key]] || []), cur];
        return acc;
      }, [])
      .filter(Boolean);
  }

  const data = groupArrayBy([...portfolios, ...investments], "goalId");

  return (
    <>
      <SEO
        title="Invierte y Ahorra - Rokin"
        description="Invierte en tus objetivos, simplifica tu ahorro y alcanza tus metas"
      />
      {!isMobile && (
        <LayoutMobileInvestments activeInvest>
          <InvestmentsLayout />
        </LayoutMobileInvestments>
      )}
      {isMobile && (
        <LayoutOnBoarding activeInvest activeRokipedia={false}>
          <InvestmentsLayout />
        </LayoutOnBoarding>
      )}
    </>
  );
};

export default Investments;
