import React, { useState, useEffect, useCallback, useContext } from "react";
import { useLocation } from "@reach/router";
import { makeStyles, Theme } from "@material-ui/core";
import { NewGoal } from "images/Investments/Goals";
import CardGoal from "./CardGoals/CardGoal";
import CardMonitoreo from "./CardGoals/CardMonitoreo";
import { Route, MovementType } from "@interfaces";
import { navigate } from "gatsby";
import { tooltipDesktop } from "images/Goal";
import { AddMonitor } from "@images";
import CardGoalNoInvest from "./CardGoals/CardGoalNoInvest";
import { ProfileContext, NewInvestContext, AppContext } from "@context";
import { useGoals, useCurrentUser } from "@apollo";
import {
  DEPOSIT_ICON_WHITE,
  ADD_GOAL_WHITE,
} from "images/AWS/Investments/index";

interface propsGoals {
  data: any;
}

const Goals: React.FC = ({ data }: propsGoals) => {
  const [noGoal, setNoGoal] = useState<boolean>(true);
  const { pathname, search: pathSearch } = useLocation();
  const { openNoGoalWarningModal, openNoPersonalDataWarningModal } =
    useContext(AppContext);
  const { profileForm } = useContext(ProfileContext);
  const { openAddFundsModal, startFlow } = useContext(NewInvestContext);
  const { goals } = useGoals();

  useEffect(() => {
    if (goals.length > 0) {
      setNoGoal(false);
    }
  }, [goals]);
  const classes = useStyles({ noGoal });

  const { user: currentUser } = useCurrentUser();
  console.log(data);
  // function groupArrayBy(arr, key) {
  //   return arr
  //     .reduce((acc, cur) => {
  //       acc[cur[key]] = [...(acc[cur[key]] || []), cur];
  //       return acc;
  //     }, [])
  //     .filter(Boolean);
  // }
  //
  // const data = groupArrayBy([...portfolios, ...investments], "goalId");

  const goalsNotInvestments = useCallback(() => {
    const idsToDelete: number[] = [];
    data.forEach((element: { goalId: number }[]) => {
      idsToDelete.push(element[0].goalId);
    });

    const goalList = goals.filter((obj) => !idsToDelete.includes(obj.id));
    return goalList;
  }, [data, goals]);

  const handleAddFundsClick = () => {
    if (goals.length == 0) {
      openNoGoalWarningModal();
    } else {
      if (
        !currentUser?.isIdentityValidated ||
        !currentUser?.isPersonalDataCompleted ||
        !currentUser?.isSpecialDataCompleted
      ) {
        openNoPersonalDataWarningModal();
      } else {
        openAddFundsModal(null);
        startFlow({
          isReinvest: true,
          movementType: MovementType.DEPOSIT,
        });
      }
    }
  };

  return (
    <div className={classes.containerAll}>
      <section className={classes.container}>
        <div className={classes.titleCardContainer}>
          <span className={classes.txtPrincipal}>Mis objetivos</span>
          <div className={classes.buttonTextContainer}>
            <span className={classes.txtSecondary}>
              Revisa cuánto has ganado hasta el momento
            </span>
            <button
              className={classes.depositButton}
              onClick={handleAddFundsClick}
            >
              <div className={classes.depositButtonContainer}>
                <img src={DEPOSIT_ICON_WHITE} alt="" />
                <span className={classes.newObjectiveText}>Depositar</span>
                <span></span>
              </div>
            </button>
            <button
              className={classes.newObjectiveButton}
              onClick={() => navigate(Route.createGoal)}
            >
              <div className={classes.depositButtonContainer}>
                <img src={ADD_GOAL_WHITE} alt="" />
                <span className={classes.newObjectiveText}>Nuevo Objetivo</span>
                <span></span>
              </div>
            </button>
          </div>
        </div>
      </section>
      <div className={classes.containerCards}>
        {data?.map((dataGrouped) => (
          <CardGoal investmentsData={dataGrouped} />
        ))}
        {goalsNotInvestments()?.map((goal) => (
          <CardGoalNoInvest data={goal} />
        ))}
      </div>
      {/**<div className={classes.newGoal}>
        <img onClick={() => navigate(Route.createGoal)} src={NewGoal} />
        {noGoal && (
          <div className={classes.tooltip}>
            <img src={tooltipDesktop} />
            <span className={classes.textTooltip}>
              <strong>Aun no has creado un objetivo!</strong> Haz click aquí
              para crear uno nuevo y empezar a invertir.
            </span>
            <button
              onClick={() => navigate(Route.createGoal)}
              className={classes.buttonTooltip}
            >
              Ok
            </button>
          </div>
        )}
        </div>*/}
      {/**<section className={classes.container}>
        <div className={classes.blockGrey}></div>
        <div className={classes.cardContainer}>
          <div className={classes.titleCardContainer}>
            <span className={classes.txtPrincipal}>Mis monitoreos</span>
          </div>
        </div>
      </section>
      <div className={classes.containerCards}>
        <CardMonitoreo nameGoal={"gg"} />
      </div>
      <div className={classes.newGoal}>
        <img
          onClick={() => console.log("add new monitor fund")}
          src={AddMonitor}
        />
      </div>**/}
    </div>
  );
};

export default Goals;

const useStyles = makeStyles<Theme, { noGoal: boolean }>((theme) => ({
  containerAll: {},
  cards: {
    display: "grid",
    gap: "10rem",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "5rem",
    minHeight: "auto",
    [theme.breakpoints.down(1440)]: {
      minHeight: "none",
    },
  },
  blockGrey: {
    background: "rgba(255,255,255,0)",
    width: 1400,
    height: 420,
    borderRadius: 30,
    position: "absolute",
    boxShadow: "1.25rem 0.75rem 3.125rem 0 rgba(0, 0, 0, 0)",
    [theme.breakpoints.down(1440)]: {
      width: "auto",
      background: "none",
    },
  },
  buttonTextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    gap: "1rem",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  depositButton: {
    height: "2.5rem",
    width: "40%",
    borderRadius: "10px",
    background: "#0a5669",
    boxShadow: "1.5px 1.5px 5.2px 0 rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
    },
  },
  depositButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  newObjectiveButton: {
    border: "1px solid #084554",
    height: "2.5rem",
    width: "40%",
    borderRadius: "10px",
    boxShadow: "1.5px 1.5px 5.2px 0 rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down(1240)]: {
      width: "100%",
    },
  },
  newObjectiveText: {
    color: "#fff",
    fontFamily: theme.typography.fontFamily,
    fontSize: "1rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  cardContainer: {
    borderRadius: 30,
  },
  titleCardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
  },
  cardGoal: {
    position: "relative",
  },
  txtPrincipal: {
    fontFamily: theme.typography.fontFamily,
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "1rem",
    color: "#fff",
    [theme.breakpoints.down(1240)]: {
      textAlign: "left",
    },
  },
  txtSecondary: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: "normal",
    color: "#fff",
    textAlign: "start",
    width: "100%",
    [theme.breakpoints.down(1240)]: {
      textAlign: "left",
      fontSize: "0.75rem",
    },
  },
  imageCard: {
    position: "relative",
    paddingRight: "2rem",
    "& span": {
      color: "white",
    },
    "& img": {
      width: 460,
    },
  },
  imageCardTitle: {
    position: "absolute",
    top: 80,
    left: 103,
    fontSize: 40.4,
    fontFamily: theme.typography.fontFamily,
    color: "#edebeb",
  },
  imageCardValue: {
    position: "absolute",
    top: 130,
    left: 80,
    fontSize: 60,
    fontFamily: theme.typography.fontFamily,
    color: "#edebeb",
    "& span": {
      fontSize: "1rem",
    },
  },
  titleCard: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    color: theme.palette.text.primary,
    fontWeight: 200,
  },
  valueCard: {
    fontFamily: "Montserrat",
    fontSize: "1.5rem",
    fontWeight: 600,
    "& span": {
      fontSize: "0.8rem",
      paddingRight: 2,
    },
  },
  button: {
    borderRadius: 24,
    background: "linear-gradient(161deg, #8250ff -6%, #181331 117%)",
    padding: "0.2rem 0.4rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 230,
    height: 47,
    color: "white",
    "& span": {
      display: "block",
      paddingLeft: 10,
      fontSize: 20,
      fontWeight: 300,
    },
  },
  containerCard: {
    display: "flex",
    gap: "3rem",
    paddingBottom: "3rem",
  },
  newGoal: {
    width: "90%",
    margin: "0 auto",
    paddingLeft: "6rem",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(1440)]: {
      paddingTop: "10rem",
      paddingLeft: 0,
    },
    "& >img": {
      cursor: "pointer",
      zIndex: 4,
      paddingTop: (noGoal) => (!noGoal ? 400 : "auto"),
    },
  },
  tooltip: {
    position: "relative",
  },
  textTooltip: {
    fontSize: 14,
    fontWeight: 300,
    position: "absolute",
    top: 20,
    width: 300,
    left: 40,
    "& strong": {
      fontWeight: 500,
    },
  },
  buttonTooltip: {
    position: "absolute",
    fontWeight: 600,
    color: "white",
    bottom: 0,
    right: 0,
    cursor: "pointer",
    height: 60,
    width: 122,
  },
  containerCards: {
    marginTop: "3rem",
    margin: "0 auto",
    paddingBottom: "2rem",
    background: "rgba(0,0,0,0)",
    [theme.breakpoints.down(1240)]: {
      padding: "10px",
      background: "white",
      borderRadius: "21.8px 21.8px 0 0",
      paddingBottom: "6rem",
    },
  },
}));
