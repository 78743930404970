import React, { useState, useEffect, useCallback } from "react";
import {
  makeStyles,
  TablePagination,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import {
  DocumentationIcon,
  GraphicIcon,
  ListIcon,
  GraphicIconOff,
  ListIconOn,
} from "images/Investments/Goals";
import { ButtonText } from "@components/Reusables";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import Table from "@components/Investments/CardGoals/TableGoal";
import { defaultDataSecondary, chartOptions } from "@components";
import { useHistoricalProfitByGoal } from "@apollo";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

interface propsDataGoal {
  className?: string;
  data: any;
  valueGoal:any;
}

const GraphicGoal = ({ className, data,valueGoal }: propsDataGoal) => {
  const [ value, setValue ] = useState<number>(0);
  const [ valueRequired, setValueRequired ] = useState<number>(0);
  const classes = useStyles({ isTable });
  const isMobile = useMediaQuery("(max-width: 1000px)");
  const { historicalProfitByGoal, loading } = useHistoricalProfitByGoal(
    data[0].goalId
  );
  const [isGraphic, setIsGraphic] = useState<boolean>(true);
  const [isTable, setIsTable] = useState<boolean>(false);
  const [portfolioPage, setPortfolioPage] = useState<any>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const handleViewGraphic = () => {
    setIsTable(false);
    setIsGraphic(true);
  };

  const handleViewTable = () => {
    setIsGraphic(false);
    setIsTable(true);
  };

  function portfolioArray() {
    const separateElements = [];
    const multiElements = data;

    if (multiElements !== undefined) {
      for (let i = 0; i < multiElements.length; i += 3) {
        const oneRow = [];
        oneRow.push(
          multiElements.slice(i, i + 3).map((item) => {
            return item;
          })
        );
        separateElements.push(oneRow);
      }
    }
    return separateElements;
  }

  useEffect(() => {
    if (portfolioArray().length !== 0) {
      setPortfolioPage(portfolioArray()[currentPage][0]);
    }
  }, [currentPage, data]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const graphData = useCallback(() => {
    if (historicalProfitByGoal) {
      const values = historicalProfitByGoal.map((e) => e.value);
      const labels = historicalProfitByGoal.map(
        (e) => e.date.split("-")[0] + "-" + e.date.split("-")[1]
      );

      return {
        labels: labels,
        datasets: [
          {
            fill: true,
            label: "Saldo",
            data: values,
            borderColor: "#008296",
            backgroundColor: "rgba(0, 169, 194, 0.3)",
            pointStyle: "circle",
            radius: 6,
            pointRadius: 1,
            lineTension: 0.3,
          },
        ],
      };
    } else {
      return defaultDataSecondary;
    }
  }, [historicalProfitByGoal]);

  const getTotalInvested = (data) => {
    const sum = data.reduce((accumulator, object) => {
      return accumulator + object.currentValue;
    }, 0);
    return sum
  };

  useEffect(() => {
    if(data !== undefined){
      setValueRequired(valueGoal)
      setValue(getTotalInvested(data))
    }
  }, [data, valueGoal]);

  return (
    <section className={`${classes.container} ${className}`}>
      <div className={classes.cardContainer}>
        {isGraphic && (
          <div>
            <div className={classes.saldo}>
              <div></div>Saldo
            </div>
            <Line
              style={{ width: 700 }}
              options={chartOptions}
              data={graphData()}
            />{" "}
          </div>
        )}
        {isTable && (
          <div className={classes.table}>
            {portfolioPage?.map((portfolio) => (
              <Table data={portfolio} />
            ))}
            <TablePagination
              className={classes.tablePagination}
              count={data.length}
              page={currentPage}
              onPageChange={handlePageChange}
              rowsPerPage={3}
              rowsPerPageOptions={[3]}
            />
          </div>
        )}
        {/** THIS IS ON MOBILE GRAPH ON**/}
        {isGraphic && isMobile && (
          <div style={{ display: "flex", gap: 40, justifyContent: "center" }}>
            <div className={classes.viewGraphic} onClick={handleViewGraphic}>
              <div className={`${classes.buttonIcon} buttonIcon`}>
                <img src={GraphicIcon} />
              </div>
              <span>Ver gráfico</span>
            </div>
            <div className={classes.viewTable} onClick={handleViewTable}>
              <div className={`${classes.buttonIcon} `}>
                <img src={ListIcon} />
              </div>
              <span>Ver tabla</span>
            </div>
          </div>
        )}
        {/** THIS IS ON MOBILE GRAPH OFF**/}
        {isMobile && isTable && (
          <div style={{ display: "flex", gap: 40, justifyContent: "center" }}>
            <div className={classes.viewTable} onClick={handleViewGraphic}>
              <div className={`${classes.buttonIcon} `}>
                <img src={GraphicIconOff} />
              </div>
              <span>Ver gráfico</span>
            </div>
            <div className={classes.viewGraphic} onClick={handleViewTable}>
              <div className={`${classes.buttonIcon} buttonIcon`}>
                <img src={ListIconOn} />
              </div>
              <span>Ver tabla</span>
            </div>
          </div>
        )}
        {/** THIS IS ON DESKTOP GRAPH ON**/}
        {!isMobile && isGraphic && (
          <div style={{ display: "flex", gap: 22 }}>
            <div className={classes.viewGraphic} onClick={handleViewGraphic}>
              <div className={`${classes.buttonIcon} buttonIcon`}>
                <img src={GraphicIcon} />
              </div>
              <span>Ver gráfico</span>
            </div>
            <div className={classes.viewTable} onClick={handleViewTable}>
              <div className={`${classes.buttonIcon} `}>
                <img src={ListIcon} />
              </div>
              <span>Ver tabla</span>
            </div>
          </div>
        )}
        {/** THIS IS ON DESKTOP GRAPH ON**/}
        {!isMobile && !isGraphic && (
          <div style={{ display: "flex", gap: 22 }}>
            <div className={classes.viewTable} onClick={handleViewGraphic}>
              <div className={`${classes.buttonIcon}`}>
                <img src={GraphicIconOff} />
              </div>
              <span>Ver gráfico</span>
            </div>
            <div className={classes.viewGraphic} onClick={handleViewTable}>
              <div className={`${classes.buttonIcon} buttonIcon`}>
                <img src={ListIconOn} />
              </div>
              <span>Ver tabla</span>
            </div>
          </div>
        )}
      </div>
      
    </section>
  );
};

export default GraphicGoal;

const useStyles = makeStyles<Theme, { isTable: boolean }>((theme) => ({
  container: {},
  tableTitle: {
    fontSize: 16,
    color: "#008296",
    fontWeight: 600,
    fontFamily: "Montserrat",
    padding: "0.625rem 0 0.9375rem 3.75rem",
    position: "absolute",
    top: 10,
    left: 85,
  },
  cardContainer: {
    padding: "3.125rem 2.8125rem 1.25rem 6.25rem",
    background: "white",
    borderRadius: 30,
    boxShadow: "1.25rem 0.75rem 3.125rem 0 rgba(0,0,0,0.2)",
    minHeight: 470,
    [theme.breakpoints.down(1000)]: {
      padding: "0.625rem 0.9375rem",
      background: "none",
      boxShadow: "none",
      minHeight: "auto",
      
      "& >div": {
        paddingTop: "3rem",
      },
    },
  },
  bottomGraphic: {
    display: "flex",
    marginTop: "1rem",
    justifyContent: "left",
    gap: 20,
  },
  leftBottomGraphic: {
    display: "flex",
    gap: 30,
    "& div": {
      display: "flex",
    },
  },
  rightBottomGraphic: {
    display: "flex",
    gap: 5,
    alignItems: "center",
    paddingTop: "3rem",
    "& button": {
      color: theme.palette.text.disabled,
      fontSize: "1rem",
    },
  },
  buttonIcon: {
    border: "0.0625rem solid grey",
    padding: "0.625rem 0.625rem",
    boxShadow: "0.25rem 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.2)",
    borderRadius: 10,
    cursor: "pointer",
  },
  viewGraphic: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    fontSize: 12,
    cursor: "pointer",
    color: theme.palette.primary.main,
    "& .buttonIcon": {
      border: `0.0625rem solid ${theme.palette.primary.main}`,
    },
    "& >span": {
      marginTop: 5,
    },
  },
  viewTable: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    fontSize: 12,
    cursor: "pointer",
    color: theme.palette.text.disabled,
    "& .buttonIcon": {
      border: `0.0625rem solid ${theme.palette.primary.main}`,
    },
    "& >span": {
      marginTop: 5,
    },
  },
  table: {
    height: 350,
    width: 700,
    [theme.breakpoints.down(1000)]: {
      width: "auto",
      height: "auto",
      paddingLeft: 10,
    },
  },
  saldo: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: 20,
    left: 130,
    fontSize: 14,
    fontWeight: "bold",
    [theme.breakpoints.down(1000)]: {
      borderLeft: 35,
      left: 20,
      top: 40,
    },
    "& >div": {
      background: theme.palette.primary.main,
      width: 20,
      height: 20,
      borderRadius: "100%",
      marginRight: 5,
      [theme.breakpoints.down(1300)]: {
        width: 10,
        height: 10,
      },
    },
  },
  tablePagination: {
    position: "absolute",
    top: "23.6rem",
    right: "2rem",
    color: "#008296",
    [theme.breakpoints.down(1000)]: {
      top: 0,
      right: 0,
      position: "relative",
    },
  },
  valueMobile:{
    borderRadius:"14px",
    border:"1px solid #8352fd",
    width:"100%",
    height:"3.2rem",
    display: "flex",
    justifyContent:"center",
    alignItems:"center",
    "& p":{
      textAlign: "center",
    }
  },
}));
